import { useAtomValue } from "jotai";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { loginAtom } from "../api-hooks/auth/login";
import { getLoggedInPath } from "../api-hooks/auth/useAuthentication";
import { useUserDetails } from "../api-hooks/auth/useUserDetails";
import { InlineNotification } from "../shared/InlineNotification/InlineNotification";

const NotFound = () => {
  const router = useRouter();

  const loginState = useAtomValue(loginAtom);
  const userDetails = useUserDetails();

  useEffect(() => {
    router.push(
      loginState.isLoggedIn && userDetails.status === "success"
        ? getLoggedInPath(userDetails.data.userType)
        : "/",
      undefined,
      { shallow: true },
    );
  }, [router]);
  return (
    <div style={{ margin: "auto" }}>
      <InlineNotification type="loading" message="" />
    </div>
  );
};
export default NotFound;
